import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ICensusItem } from '@/types.ts';

const handleCell = ({ cell, row }: CellContext<Partial<ICensusItem>, any>) => {
  const errors = row.original.possible_errors;
  const key = cell.column.id;
  const value = cell.getValue();
  let relevantErrors = [];

  if (errors && errors.length) {
    relevantErrors = errors.filter((error) => error.field_name === key);
  }

  return (
    <div className={relevantErrors.length ? 'bg-[yellow]' : ''}>{value}</div>
  );
};

export const columns: ColumnDef<Partial<ICensusItem>>[] = [
  {
    accessorKey: 'first_name',
    header: 'First Name*',
    cell: handleCell,
  },
  {
    accessorKey: 'middle_name',
    header: 'Middle Name',
    cell: handleCell,
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name*',
    cell: handleCell,
  },
  {
    accessorKey: 'email',
    header: 'Primary Email Address*',
    cell: handleCell,
  },
  {
    accessorKey: 'hire_dt',
    header: 'Hire Date*',
    cell: handleCell,
  },
  {
    accessorKey: 'termination_dt',
    header: 'Termination Date',
    cell: handleCell,
  },
  {
    accessorKey: 'rehire_dt',
    header: 'Rehire Date',
    cell: handleCell,
  },
  {
    accessorKey: 'title',
    header: 'Job Title',
    cell: handleCell,
  },
  {
    accessorKey: 'annual_salary_gross',
    header: 'Gross Salary',
    cell: handleCell,
  },
  {
    accessorKey: 'ytd_hours_worked',
    header: 'YTD Hours Worked',
    cell: handleCell,
  },
  {
    accessorKey: 'social_security_number',
    header: 'Social Security Number',
    cell: handleCell,
  },
  {
    accessorKey: 'date_of_birth',
    header: 'Birthdate',
    cell: handleCell,
  },
  {
    accessorKey: 'address_line_1',
    header: 'Street Address 1',
    cell: handleCell,
  },
  {
    accessorKey: 'address_line_2',
    header: 'Street Address 2',
    cell: handleCell,
  },
  {
    accessorKey: 'address_city',
    header: 'City',
    cell: handleCell,
  },
  {
    accessorKey: 'address_state',
    header: 'State',
    cell: handleCell,
  },
  {
    accessorKey: 'address_postal_code',
    header: 'Zip Code',
    cell: handleCell,
  },
  {
    accessorKey: 'phone_number_home',
    header: 'Home Phone',
    cell: handleCell,
  },
  {
    accessorKey: 'phone_number_mobile',
    header: 'Mobile Phone',
    cell: handleCell,
  },
];
