import { useCensusStore } from '@/routes/dashboard/census/store.ts';
import CensusUpload from '@/routes/dashboard/census/census-upload.tsx';
import CensusVerify from '@/routes/dashboard/census/census-verify.tsx';
import CensusRemediate from '@/routes/dashboard/census/census-remediate.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';

const Census = () => {
  // Global Census State
  const { activeView, handleClickCancel, showCancelModal, setShowCancelModal } =
    useCensusStore();

  const getActiveView = () => {
    switch (activeView) {
      case 'upload':
        return <CensusUpload />;
      case 'errors':
        return <CensusVerify />;
      case 'remediate':
        return <CensusRemediate />;
      default:
        return <CensusUpload />;
    }
  };

  return (
    <div>
      <Dialog open={showCancelModal} onOpenChange={setShowCancelModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancel your upload?</DialogTitle>
            <DialogDescription className="pt-4">
              This action cannot be undone and any progress on your file upload
              will not be saved.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleClickCancel}>Yes</Button>
            <Button variant="outline" onClick={() => setShowCancelModal(false)}>
              No
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {getActiveView()}
    </div>
  );
};

export default Census;
