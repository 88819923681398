import { Button } from '@/components/ui/button.tsx';
import { BiDownload, BiFile } from 'react-icons/bi';
import Banner from '@/components/banner.tsx';
import { Progress } from '@/components/ui/progress.tsx';
import { sdk } from '@/api.ts';
import { useCensusStore } from '@/routes/dashboard/census/store.ts';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

// Returns an uploaded files size in a human-readable format
// Ex 782 returns "782 B", 1024 returns "1KB", 1024 * 1024 returns "1MB"
function formatFileSize(size: number) {
  if (size < 1024) {
    return `${size}B`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)}KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)}MB`;
  }
}

const CensusUpload = () => {
  // Census state
  const { handleCensusUpload, loading, setShowCancelModal } = useCensusStore();

  // View-specific state
  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [fileTypeWarning, setFileTypeWarning] = useState<boolean>(false);
  const [fileSizeWarning, setFileSizeWarning] = useState<boolean>(false);

  // Only show the progress bar / success state if there are no warnings and a file has been uploaded to the browser
  const showProgressUploadSuccess =
    files && files.length > 0 && !fileTypeWarning && !fileSizeWarning;

  // Dropzone-specific logic
  const onDrop = useCallback((acceptedFiles: File[]) => {
    // @TODO this is pretty dirty. Maybe consider using a error state object that determines what to show
    if (acceptedFiles[0].type !== 'text/csv') {
      setFileSizeWarning(false);
      setFileTypeWarning(true);
      return;
    }

    if (acceptedFiles[0].size > 25000000) {
      setFileTypeWarning(false);
      setFileSizeWarning(true);
      return;
    }

    setFileTypeWarning(false);
    setFileSizeWarning(false);
    setFiles(acceptedFiles);
    setProgress(100);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClickDownload = async () => {
    await sdk.downloadCensusTemplate();
  };

  const handleSubmit = () => {
    if (!files || files.length === 0) return;
    handleCensusUpload(files[0]);
  };

  return (
    <div className="flex max-w-[640px]">
      <div>
        <h1 className="text-2xl font-semibold">Employee Access List Upload</h1>
        <h3 className="text-lg font-semibold mt-8">Step 1</h3>
        <p>
          You can easily invite employees to Peopled using our batch upload.
          Simply download our template and add employee information. Only the
          first name, last name, email address, and hire date are required
          fields.
        </p>
        <p className="my-4">
          Note: Make sure you save the template in the CSV format.
        </p>
        <Button
          onClick={handleClickDownload}
          variant="outline"
          className="w-full"
        >
          <BiDownload className="mr-2" />
          Download Employee Access List template (CSV format)
        </Button>

        <h3 className="text-lg font-semibold mt-8">Step 2</h3>
        <p>Upload the employee access file.</p>
        <div
          {...getRootProps()}
          className="flex flex-col justify-center items-center mt-4 p-6 rounded text-sm border-2 border-dashed border-primary"
        >
          <BiDownload size={40} />
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="mt-4">Drop the file here ...</p>
          ) : (
            <p className="mt-4">Drag and drop file here or choose file</p>
          )}
        </div>

        <div className="flex justify-between items-center">
          <p className="my-4">Supported format: CSV</p>
          <p className="my-4">Maximum size: 25MB</p>
        </div>

        {fileTypeWarning ? (
          <Banner
            title="The file is not the correct format."
            description="Please make sure the file is in CSV format and try again. "
          />
        ) : null}

        {fileSizeWarning ? (
          <Banner
            title="The file is too large."
            description="Please make sure the file less than 25MB and try again."
          />
        ) : null}

        {showProgressUploadSuccess ? (
          <div className="text-sm bg-accent rounded p-4 border border-primary">
            <div className="flex justify-between">
              <div className="flex items-center">
                <BiFile size={24} />
                <p className="ml-2">{(files[0] as any).name}</p>
              </div>
              <p>{formatFileSize((files[0] as any).size)}</p>
            </div>
            <div className="flex gap-x-2 items-center mt-2">
              <Progress value={progress} />
              <p>{progress}%</p>
            </div>
          </div>
        ) : null}

        <div className="mt-4 flex gap-x-2">
          <Button
            disabled={!files.length || fileTypeWarning || fileSizeWarning}
            loading={loading}
            onClick={handleSubmit}
          >
            Next
          </Button>
          <Button
            disabled={!files.length}
            variant="outline"
            onClick={() => setShowCancelModal(true)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CensusUpload;
