import { ICensusItem } from '@/types.ts';
import { create } from 'zustand';
import { sdk } from '@/api.ts';

interface CensusState {
  activeView: 'upload' | 'errors' | 'remediate';
  censusData: Partial<ICensusItem>[];
  censusDataWithErrors: Partial<ICensusItem>[];
  handleClickCancel: () => void;
  handleClickRemediate: () => void;
  handleCensusUpload: (_file: File) => void;
  loading: boolean;
  setShowCancelModal: (_show: boolean) => void;
  showCancelModal: boolean;
}

export const useCensusStore = create<CensusState>((set) => ({
  activeView: 'upload',
  censusData: [],
  censusDataWithErrors: [],
  handleCensusUpload: (file) => {
    set({ loading: true });
    sdk
      .uploadCensusFile(file)
      .then((response) => {
        set({
          activeView: 'errors',
          censusData: response.data.census_data,
          censusDataWithErrors: response.data.census_data.filter(
            (item: ICensusItem) => item.possible_errors?.length
          ),
          loading: false,
        });
      })
      .catch((error) => {
        set({ loading: false });
        console.error('error: ', error);
      });
  },
  handleClickRemediate: () => {
    set({ activeView: 'remediate' });
  },
  handleClickCancel: () => {
    set({
      activeView: 'upload',
      censusData: [],
      censusDataWithErrors: [],
      showCancelModal: false,
    });
  },
  loading: false,
  showCancelModal: false,
  setShowCancelModal: (show: boolean) => set({ showCancelModal: show }),
}));
